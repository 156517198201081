import React from 'react'
import {graphql} from 'gatsby'
import Head from '../components/head'
import Layout from '../components/layout'

export const query = graphql`
  query($slug: String!, $imagePath: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      fields {
        slug
      }
      frontmatter {
        date
        title
        description
        image
      }
      html
    },
    file(relativePath: {eq: $imagePath}) {
      publicURL
    }
  }
`

const Blog = (props) => {
    return (
        <Layout>
          <Head title={props.data.markdownRemark.frontmatter.title} isBlogPost={true} image = {props.data.file.publicURL}
                  url = {props.data.markdownRemark.fields.slug} description = {props.data.markdownRemark.frontmatter.description}/>
            <h1>{props.data.markdownRemark.frontmatter.title}</h1>
            <p>{props.data.markdownRemark.frontmatter.date}</p>
            <div dangerouslySetInnerHTML={{__html: props.data.markdownRemark.html}}></div>
        </Layout>
    )
}

export default Blog